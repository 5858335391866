<template>
  <CCard>
    <CCardHeader>
      <h3>Eliminar Marca</h3>
    </CCardHeader>

    <CCardBody>
      <div class="form-group">
        <label>Marca:</label>
        <p>{{ registro.marca }}</p>
      </div>
      <div class="form-group">
        <label>Slug:</label>
        <p>{{ registro.slug }}</p>
      </div>
      <div class="form-group">
        <label>Descripción:</label>
        <p>{{ registro.descripcion }}</p>
      </div>
      <div class="form-group">
        <label>Idioma:</label>
        <p>{{ registro.idioma }}</p>
      </div>
      <div class="form-group">
        <button type="button" class="btn btn-danger" @click="deleted()">
          <span class="cil-trash btn-icon mr-2"></span>
          Eliminar
        </button>
        <router-link to="/ecoMarcas/List">
          <CButton class="ml-2" color="secondary">
            <span class="cil-x-circle btn-icon mr-2"></span>
            Cancelar
          </CButton>
        </router-link>
      </div>
    </CCardBody>
  </CCard>
</template>
<script>
import httpService from "@/services/httpService"

export default {
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {}
    };
  },

  methods: {
    async getRegistro() {
      this.id = this.$route.params.id
      if (this.id !== "0") {
        let url = "eco-marcas/" + this.id
        let response = await httpService.get(url)
        let respuesta = response.data
        this.registro = respuesta.registros[0]
      }
    },
    async deleted() {
      let url = "eco-marcas/" + this.id
      let response = await httpService.delete(url);
      this.$vToastify.success("Operación realizada con éxito", "Éxito");
      this.$router.back();
    }
  },

  mounted() {
    this.getRegistro();
  }
};
</script>